import React from 'react';
import { Link } from 'gatsby';
import { Button, Space } from 'antd';
import { AppstoreAddOutlined, InstagramFilled, FileImageOutlined, EyeOutlined } from '@ant-design/icons';

// import './homepage.css';

// 首页 新建购买记录，查询修改购买记录导航按钮

const ManageNav = () => {
  return (
    <div className="home-page-div">
      <Space direction="vertical" size="large">
        <Link className="nav-link" to="/adminers">
          <Button type="primary" size="large" icon={<AppstoreAddOutlined />}>管理员表</Button>
        </Link>
        <Link className="nav-link" to="/productsmanagelist">
          <Button type="primary" size="large" icon={<InstagramFilled />}>镜架墨镜</Button>
        </Link>
        <Link className="nav-link" to="/lensmanagelist">
          <Button type="primary" size="large" icon={<EyeOutlined />}>镜片列表</Button>
        </Link>
        <Link className="nav-link" to="/imageslist">
          <Button type="primary" size="large" icon={<FileImageOutlined />}>图片管理</Button>
        </Link>
      </Space>
    </div>
  )
}

export default ManageNav;
