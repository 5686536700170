import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import ManageNav from '../components/page_manage';

const ManagePage = () => (
  <SiteLayout>
    <SEO title="Manage" />
    <ManageNav></ManageNav>
  </SiteLayout>
)

export default ManagePage;
